// Component > places
.places {
  .file {
    .image{
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }

  .facilities {
    .distance {
      top: 0;
      right: 0;
      font-size: 1.2em;
    }
  }
}