// _base/base
* {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-default-family;
  font-weight: $font-default-weight;
  font-size: $font-default-size;
  font-style: $font-default-style;
  line-height: $line-height-base;
  color: $color-text-base;
  -moz-transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.block-pc-only {
  display: block;
  
  @include media-xs {
    display: none;
  }
}

.block-sp-only {
  display: none;

  @include media-xs {
    display: block;
  }
}

.inline-block-pc-only {
  display: inline-block;
  
  @include media-xs {
    display: none;
  }
}

.inline-block-sp-only {
  display: none;

  @include media-xs {
    display: inline-block;
  }
}
