// 3rdParty
// DropZone
@import '~dropzone/dist/dropzone';

.dropzone {
  border: 2px dashed #ddd;

  .dz-preview {
    opacity: 0;
    z-index: 0;
    
    .dz-image {
      width: auto;
      height: auto;
    }

    .dz-progress {
      display: none;
    }

    .dz-remove {
      font-size: 30px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      background: #c00;
      color: #fff;
      width: 30px;
      height: 30px;
      text-decoration: none;
      border-radius: 0 15px 0 0;
      line-height: 1.2;
    }
  }
}

.dz-drag-hover {
  border: 2px solid #ddf;
}