// _base/mixin

@mixin media-xs() {
  @media screen and (max-width: $breakpoint-small) {
    @content;
  }
}
  
@mixin media-sm() {
  @media screen and (min-width: $breakpoint-medium) {
    @content;
  }
}
  
 @mixin media-md() {
  @media screen and (max-width: $breakpoint-large) {
    @content;
  }
}
  
@mixin media-lg() {
  @media screen and (min-width: $breakpoint-extralarge) {
    @content;
  }
}
