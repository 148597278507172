// Component > bootstrap
.badge-extend {
  padding-top: 0;
  padding-bottom: 0;

  .name {
    display: inline-block;
  }
  .value {
    display: inline-block;
    background: rgba(255,255,255,0.25);
    color: #fff;
    padding: 2px 4px;
    margin-left: 0.25em;
  }
}