// _variables/general
//      Typography
$font-default-family: "fot-tsukuardgothic-std", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック", YuGothic, メイリオ, Meiryo, sans-serif;
$font-default-size: 0.9em;
$font-default-weight: 400;
$font-default-style: normal;
$line-height-base: 1.6;

//      Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//      Breakpoints
$breakpoint-small: 767px;
$breakpoint-medium: 768px;
$breakpoint-large: 1023px;
$breakpoint-extralarge: 1024px;