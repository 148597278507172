// Elements > contents
.page {
  &--title {
    display: block;
    position: relative;
    border-left: 8px solid $primary-accent-color;

    @include media-xs {
      margin-bottom: 40px !important;
    }

    &--menus {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      @include media-xs {
        top: auto;
      }

      a {
        color: $primary-accent-color;
        font-size: 20pt;
        padding: 10px;
        opacity: 0.5;

        &:hover {
          color: $primary-accent-color;
          opacity: 1;
        }
      }
    }
  }

  &--contents-menu {
    display: block;

    a {
      color: $primary-accent-color !important;
      font-size: 15pt;
      padding: 5px;
      opacity: 0.5;

      &:hover {
        color: $primary-accent-color !important;
        opacity: 1;
      }
    }
  }


  &--api-tool {
    .item {
      list-style-type: none;

      .title {

      }
    }
  }
}