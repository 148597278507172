// Elements > loading
@keyframes textLoadingDot{
  0% {
    opacity: 0;
  }

  30%,70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#IDBlockLoading {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(255,255,255,0.8);
  z-index: 9999;
}
#IDBlockLoading > .spinner {
  display: none;
  color: #fff;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.text-loading {
  .message {
    .dot {
      animation-name: textLoadingDot;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      opacity: 0;
    }

    .dot:nth-child(2) {
      animation-delay: 250ms;
    }

    .dot:nth-child(3) {
      animation-delay: 500ms;
    }

    .dot:nth-child(4) {
      animation-delay: 750ms;
    }
  }
}