// _base/contents
// コンテンツ設定
.campjin {
  &--sidebar {
    &--background {
      background: $background-sidebar-default;
    }
  }
}

.cp {
  &--text {
    &--xxs {
      font-size: .75em;
    }
  }
}